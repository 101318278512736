<template>
  <main id="project" class="recomendmenu">
    <div class="container">
      <Step :step="2" />

      <div class="row">
        <div class="col-sm-12">
          <div class="panel">
            <div class="panel-heading">
              <h2 class="pageTitle">
                入力画面<span>Customize</span>
              </h2>
            </div>
            <div class="panel-body recommend_input">
              <h3 class="menuannounce">
                任意のキャラクター名と推奨100カテゴリーまたは任意のカテゴリー名と推奨100キャラクターを入力(入力はそれぞれ1つ)
              </h3>
              <p class="dbnotice">
                「計算する」ボタンを押すと、ご入力いただいたキャラクター名とカテゴリー名がデータベースに存在するかの確認を行います。<br>
                存在しない場合はエラーが通知されますので、新しいワードを入力してください。
              </p>
              <div v-if="typeRecommendInput === typeVariable.CATEGORY && messageErrorCategory" class="dbpoint">
                <p>{{ messageErrorCategory }}</p>
              </div>
              <div v-if="typeRecommendInput === typeVariable.CHARACTER && messageErrorCharacter" class="dbpoint">
                <p>{{ messageErrorCharacter }}</p>
              </div>
              <ul class="nav nav-tabs">
                <li
                  :class="[
                    typeRecommendInput === typeVariable.CHARACTER ? 'active' : '',
                  ]"
                  @click="changeTypeVariable(typeVariable.CHARACTER)"
                >
                  <a href="#character" data-toggle="tab">キャラクター名から計算</a>
                </li>
                <li
                  :class="[
                    typeRecommendInput === typeVariable.CATEGORY ? 'active' : '',
                  ]"
                  @click="changeTypeVariable(typeVariable.CATEGORY)"
                >
                  <a href="#category" data-toggle="tab">カテゴリー名から計算</a>
                </li>
              </ul>

              <div class="tab-content">
                <div id="character" :class="['tab-pane', typeRecommendInput === typeVariable.CHARACTER ? 'active' : '']">
                  <div class="input_list form-group">
                    <div class="input_item">
                      <input
                        v-model="character.value"
                        type="text"
                        :class="['form-control', character.error ? 'errorproject' : '']"
                        placeholder="キャラクター名"
                        maxlength="255"
                        @input="character.error = false"
                      >
                    </div>
                    <div class="input_item">
                      <a href="https://hp.plug-ip.com/queries/#category" class="outer" target="_blank">推奨100のカテゴリーはこちら<i class="fa fa-external-link-square pl5" /></a>
                    </div>
                  </div>
                </div>
                <div id="category" :class="['tab-pane', typeRecommendInput === typeVariable.CATEGORY ? 'active' : '']">
                  <div class="input_list form-group">
                    <div class="input_item">
                      <input
                        v-model="category.value"
                        type="text"
                        :class="['form-control', category.error ? 'errorproject' : '']"
                        placeholder="カテゴリー名"
                        maxlength="255"
                        @input="category.error = false"
                      >
                    </div>
                    <div class="input_item">
                      <a href="https://hp.plug-ip.com/queries/#character" class="outer" target="_blank">推奨100のキャラクターはこちら<i class="fa fa-external-link-square pl5" /></a>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <ul class="actionVertical clearfix">
                  <li>
                    <button type="submit" class="btn btn01" @click="submit">
                      計算する
                    </button>
                  </li>
                  <li>
                    <button type="button" class="btn btn02" @click="goToSelectMenu">
                      戻る
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalConfirm ref="modalConfirm">
      <div slot="header">
        <h4 class="modal-title">
          plug-ip.com
        </h4>
      </div>
      <div slot="content">
        <p>全てのワードがデータベースにあります。計算しますか？</p>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-darkblue" data-dismiss="modal" @click="calculation">
          OK
        </button>
        <button type="button" class="btn btn-darkgray" data-dismiss="modal">
          キャンセル
        </button>
      </div>
    </ModalConfirm>
    <button id="button-toggle-modal" style="display: none" data-toggle="modal" data-target="#modal-confirm" />
  </main>
</template>

<script>
import Step from '../../components/project/Step'
import store from '../../store'
import { typeVariable } from '../../common/constant'
import { actions as actionsProject } from '../../store/modules/project'
import { createProjectApi, validateWordApi } from '../../services/api'
import { actions as actionsApp } from '../../store/modules/app'
import { trimValue } from '../../shared/common'
import ModalConfirm from '../../components/modal/ModalConfirm'
import { actions as actionsUser } from '../../store/modules/user'
import router from '../../routers'

export default {
  name: 'RecommendInput',
  components: {
    Step, ModalConfirm
  },
  data() {
    return {
      category: {},
      character: {},
      typeVariable: typeVariable,
      messageErrorCategory: '',
      messageErrorCharacter: ''
    }
  },
  computed: {
    typeRecommendInput() {
      return store.getters.typeRecommendInput
    }
  },
  watch: {
    category: {
      handler: function(value) {
        store.dispatch(actionsProject.SET_CATEGORY_RECOMMEND_INPUT, value)
      },
      deep: true
    },
    character: {
      handler: function(value) {
        store.dispatch(actionsProject.SET_CHARACTER_RECOMMEND_INPUT, value)
      },
      deep: true
    },
    messageErrorCategory(value) {
      store.dispatch(actionsProject.SET_MESSAGE_ERROR_CATEGORY_RECOMMEND_INPUT, value)
    },
    messageErrorCharacter(value) {
      store.dispatch(actionsProject.SET_MESSAGE_ERROR_CHARACTER_RECOMMEND_INPUT, value)
    }
  },
  async created() {
    if (!store.getters.menu) {
      await this.$router.push({ name: 'SelectMenu' })
      this.$destroy()
      return false
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    goToSelectMenu() {
      this.$router.push({ name: 'SelectMenu' })
    },
    initData() {
      this.category = Object.keys(store.getters.categoryRecommendInput).length === 0
        ? { value: '', error: false }
        : store.getters.categoryRecommendInput
      this.character = Object.keys(store.getters.characterRecommendInput).length === 0
        ? { value: '', error: false }
        : store.getters.characterRecommendInput

      this.messageErrorCategory = store.getters.messageErrorCategoryRecommendInput
      this.messageErrorCharacter = store.getters.messageErrorCharacterRecommendInput
    },
    async changeTypeVariable(type) {
      await store.dispatch(actionsProject.SET_TYPE_RECOMMEND_INPUT, type)
    },
    async submit() {
      this.category = trimValue(this.category)
      this.character = trimValue(this.character)
      await this.validateData()
      if (this.typeRecommendInput === typeVariable.CATEGORY && !this.messageErrorCategory ||
          this.typeRecommendInput === typeVariable.CHARACTER && !this.messageErrorCharacter) {
        document.getElementById('button-toggle-modal').click()
      } else {
        // TODO
      }
    },
    async calculation() {
      if (this.typeRecommendInput === typeVariable.CATEGORY && !this.messageErrorCategory) {
        await store.dispatch(actionsProject.SET_CATEGORIES_FOR_SUBMIT, [this.category.value])
        const characters = store.getters.characters.map(item => item.name)
        await store.dispatch(actionsProject.SET_CHARACTERS_FOR_SUBMIT, characters)
      } else if (this.typeRecommendInput === typeVariable.CHARACTER && !this.messageErrorCharacter) {
        await store.dispatch(actionsProject.SET_CHARACTERS_FOR_SUBMIT, [this.character.value])
        const categories = store.getters.categories.map(item => item.name)
        await store.dispatch(actionsProject.SET_CATEGORIES_FOR_SUBMIT, categories)
      } else {
        return false
      }
      await store.dispatch(actionsApp.SET_LOADING, { active: true })
      const res = await createProjectApi({
        menu: store.getters.menu,
        categories: store.getters.categoriesForSubmit,
        characters: store.getters.charactersForSubmit
      })
      await store.dispatch(actionsApp.SET_LOADING, { active: false })
      if (res.status === 200) {
        this.messageErrorCategory = ''
        this.messageErrorCharacter = ''
        await store.dispatch(actionsProject.SET_RESULT, res.data)
        await this.$router.push({
          name: 'Result'
        })
      } else if (res.status === 401) {
        await store.dispatch(actionsUser.LOGOUT)
        await router.push({ name: 'login' })
      } else {
        alert('エラーが発生しました。 もう一度やり直してください')
      }
    },
    async validateData() {
      if (this.typeRecommendInput === typeVariable.CATEGORY) {
        this.messageErrorCategory = ''
        if (this.category.value === '') {
          this.messageErrorCategory = 'カテゴリー名を入力してください。'
          return false
        }
        await store.dispatch(actionsApp.SET_LOADING, { active: true })
        const res = await validateWordApi({
          words: [this.category.value]
        })
        await store.dispatch(actionsApp.SET_LOADING, { active: false })
        if (res.status === 200 && res.data.length > 0) {
          this.messageErrorCategory = `申し訳ございませんが、「${this.category.value}」はデータベースに入っておりません。`
          this.category.error = true
        }
      }

      if (this.typeRecommendInput === typeVariable.CHARACTER) {
        this.messageErrorCharacter = ''
        if (this.character.value === '') {
          this.messageErrorCharacter = 'キャラクター名を入力してください。'
          return false
        }
        await store.dispatch(actionsApp.SET_LOADING, { active: true })
        const res = await validateWordApi({
          words: [this.character.value]
        })
        await store.dispatch(actionsApp.SET_LOADING, { active: false })
        if (res.status === 200 && res.data.length > 0) {
          this.messageErrorCharacter = `申し訳ございませんが、「${this.character.value}」はデータベースに入っておりません。`
          this.character.error = true
        }
      }
    }
  }
}
</script>

<style scoped>
.disabled {
  pointer-events: none;
  cursor: not-allowed;
}
.outer {
  font-size: 1.3rem !important;
}
</style>
